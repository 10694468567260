import { ActionTree } from 'vuex';
import { LayoutState } from "./types";
import { RootState } from "@/store/types"

export  const actions: ActionTree<LayoutState, RootState> = {

    setActiveSidebar(context, payload: boolean) {
        context.commit("setActiveSidebar", payload)
    },

    setSidebarActiveElement(context, payload: string) {
        context.commit("setSidebarActiveElement", payload)
    },

    setActiveSidebarAppointementGeneration(context, payload: boolean) {
        context.commit("setActiveSidebarAppointementGeneration", payload)
    },

    setSidebarActiveElementAppointementGeneration(context, payload: string) {
        context.commit("setSidebarActiveElementAppointementGeneration", payload)
    },

    setActiveSidebarProgrammation(context, payload: boolean) {
        context.commit("setActiveSidebarProgrammation", payload)
    },

    setSidebarActiveElementProgrammation(context, payload: string) {
        context.commit("setSidebarActiveElementProgrammation", payload)
    },

}
