import {Module} from "vuex";
import {RootState} from "@/store/types";
import {getters} from "@/store/modules/admin/admin-getters";
import {actions} from "@/store/modules/admin/admin-actions";
import {mutations} from "@/store/modules/admin/admin-mutations";
import { AdminState } from "./types";

export const state: AdminState = {
    pageOptions: [5, 10, 20, 50, 100],
    perPage: 10,
    currentPage: 1,
    searchPurposeText: '',
    searchCategoryText: '',
    searchLocationText: '',
    searchTemplateText: '',
    scheduleTemplateModification: true,
    tableRow: { purpose: 1, category: 1, location: 1, template: 1 },
    appointmentInfo: { infoMessage: '', infoType: '', exec: false },
    programmingSpinner: false,
    appointmentGenerationSpinner: false,
    tableFilterOn: {
        'purpose': ['name'], 'category': ['name'],
        'location': ['name'], 'template': ['name']
    },
    templateFlashMessage: {showMessage: false, message: ''}
};

export const admin: Module<AdminState, RootState> = {
    namespaced : true,
    state,
    getters,
    actions,
    mutations,
};
