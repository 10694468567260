import { ActionTree } from 'vuex';
import { AdminState } from "./types";
import { RootState } from "@/store/types"
import { InfoItem } from '@/common/acl';

export  const actions: ActionTree<AdminState, RootState> = {

    setPageOptions(context, payload: Record<number, unknown>) {
        context.commit("setPageOptions", payload)
    },

    setPerPage(context, payload: number) {
        context.commit("setPerPage", payload)
    },

    setCurrentPage(context, payload: number) {
        context.commit("setCurrentPage", payload)
    },

    setSearchPurposeText(context, payload: string) {
        context.commit("setSearchPurposeText", payload)
    },

    setSearchCategoryText(context, payload: string) {
        context.commit("setSearchCategoryText", payload)
    },

    setSearchLocationText(context, payload: string) {
        context.commit("setSearchLocationText", payload)
    },

    setSearchTemplateText(context, payload: string) {
        context.commit("setSearchTemplateText", payload)
    },

    setTableRow(context, payload: { purpose: number, category: number, location: number, template: number}) {
        context.commit("setTableRow", payload)
    },

    setScheduleTemplateModification(context, payload: string) {
        context.commit("setScheduleTemplateModification", payload)
    },

    setAppointmentInfo(context, payload: InfoItem) {
        context.commit("setAppointmentInfo", payload)
    },
    
    setProgrammingSpinner(context, payload: boolean) {
        context.commit("setProgrammingSpinner", payload)
    },

    setAppointmentGenerationSpinner(context, payload: boolean) {
        context.commit("setAppointmentGenerationSpinner", payload)
    },

    setTableFilterOn(context, payload: Record<string, Array<string>>) {
        context.commit("setTableFilterOn", payload)
    },

    setTemplateFlashMessage(context, payload: { showMessage: boolean, message: string }) {
        context.commit('setTemplateFlashMessage', payload)
    }

}
