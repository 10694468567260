import { MutationTree } from 'vuex';
import { LayoutState } from "./types";

export const mutations: MutationTree<LayoutState> = {

    setActiveSidebar(state, payload: boolean) {
        state.activeSidebar = payload
    },

    setSidebarActiveElement(state, payload: string) {
        state.sidebarActiveElement = payload
    },

    setActiveSidebarAppointementGeneration(state, payload: boolean) {
        state.activeSidebarAppointementGeneration = payload
    },

    setSidebarActiveElementAppointementGeneration(state, payload: string) {
        state.sidebarActiveElementAppointementGeneration = payload
    },

    setActiveSidebarProgrammation(state, payload: boolean) {
        state.activeSidebarProgrammation = payload
    },

    setSidebarActiveElementProgrammation(state, payload: string) {
        state.sidebarActiveElementProgrammation = payload
    },
};
