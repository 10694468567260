import { GetterTree } from "vuex";
import { RootState } from "@/store/types"
import { AdminState } from './types'
import { InfoItem } from "@/common/acl";

export const getters: GetterTree<AdminState, RootState> = {

    getPageOptions(state): Record<number, unknown>{
        return state.pageOptions
    },

    getPerPage(state): number{
        return state.perPage
    },

    getCurrentPage(state): number {
        return state.currentPage
    },

    getSearchPurposeText(state): string {
        return state.searchPurposeText
    },

    getSearchCategoryText(state): string {
        return state.searchCategoryText
    },

    getSearchLocationText(state): string {
        return state.searchLocationText
    },

    getSearchTemplateText(state): string {
        return state.searchTemplateText
    },

    getTableRow(state): { purpose: number, category: number, location: number, template: number}{
        return state.tableRow
    },

    getScheduleTemplateModification(state): boolean {
        return state.scheduleTemplateModification
    },

    getAppointmentInfo(state): InfoItem{
        return state.appointmentInfo
    },

    getProgrammingSpinner(state): boolean {
        return state.programmingSpinner
    },

    getAppointmentGenerationSpinner(state): boolean {
        return state.appointmentGenerationSpinner
    },

    getTableFilterOn(state): Record<string, Array<string>> {
        return state.tableFilterOn
    },
    
    getTemplateFlashMessage(state): { showMessage: boolean, message: string } {
        return state.templateFlashMessage
    }

};
